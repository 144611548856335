import React from "react"

import { graphql } from "gatsby"
import { Heading, Box, Text } from "grommet"

import ArticleCard from "../../../components/ArticleCard"

export const query = graphql`
  query($locale: String!) {
    strapiArticleIndex(locale: { eq: $locale }) {
      id
      sub_heading
      title
      path_prefix
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allStrapiArticle(
      filter: { isDraft: { eq: false }, locale: { eq: $locale } }
    ) {
      nodes {
        authors {
          id
          bio
          email
          slug
          picture {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          name
        }
        categories {
          created_at
          id
          name
          slug
          updated_at
          published_at
        }
        content
        created_at
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        slug
        updated_at
        published_at
        title
      }
    }
  }
`

const AllArticlesPage = ({
  data: {
    allStrapiArticle: { nodes },
    strapiArticleIndex: { sub_heading, title, path_prefix },
  },
}) => {
  return (
    <Box align="center" gap="medium" pad="large">
      <Box align="center">
        <Heading level={1} margin="none" textAlign="center">
          {title}
        </Heading>
        <Text textAlign="center">{sub_heading}</Text>
      </Box>
      {nodes.map(node => (
        <ArticleCard {...node} path_prefix={path_prefix} key={node.slug} />
      ))}
    </Box>
  )
}

AllArticlesPage.propTypes = {}

export default AllArticlesPage
